import React from 'react';
import headerImg from '../images/header-agenda.jpg';

function Agenda({ sched }) {
  return (
    <div className="page-container">
      <div className="span-100" style={{ backgroundColor: "#370f6e" }}>
        <div className="max-500">
          <img src={headerImg} alt="" class="pageImage" style={{ height: 300, width: '100%', maxWidth: 500 }}/>
        </div>
      </div>

      <div className="span-100">
        <div className="max-500 page-content">
          <p>{!sched ?? 'Loading'}</p>
          <div className="agenda">
            <h2>Agenda</h2>
            <h3>Daily Schedule</h3>
            <ul className="agenda">
              {sched && sched.map((day, index) => (
                <li key={index} className="day">
                  <strong>{day.date}</strong>
                  {day.timeSlots.map((slot, idx) => (
                    <ul key={idx} className="time">
                      <li>{slot.startTime}</li>
                      {slot.endTime && <li>{slot.endTime}</li>}
                      <li>{slot.description}</li>
                      <li>{slot.location}</li>
                    </ul>
                  ))}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Agenda;