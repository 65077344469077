import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, NavLink } from 'react-router-dom';
import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import './App.css';

import DPEK from './pages/DPEK';
import Agenda from './pages/Agenda';
import Travel from './pages/Travel';
import Help from './pages/Help';

import Header from './components/header';

import navDPEK from './images/nav-dpek.svg';
import navAgenda from './images/nav-agenda.svg';
import navTravel from './images/nav-travel.svg';
import navHelp from './images/nav-help.svg';

function App() {
  var reactPlugin = new ReactPlugin();
  var appInsights = new ApplicationInsights({
    config: {
      connectionString: 'InstrumentationKey=be22e4b8-5e11-4874-951e-3bc854c6a3e0;IngestionEndpoint=https://eastus2-3.in.applicationinsights.azure.com/;LiveEndpoint=https://eastus2.livediagnostics.monitor.azure.com/;ApplicationId=a7cbca1e-472a-410c-93d0-8200e8e91386',
      enableAutoRouteTracking: true,
      extensions: [reactPlugin]      
    }
  });
  appInsights.loadAppInsights();

  const [schedule, setSchedule] = useState(null);

  useEffect(() => {
    fetch('/api/GetSchedule')
      .then(response => response.json())
      .then(data => setSchedule(data))
      .catch(error => console.error('Error fetching schedule:', error));
  }, []);

  useEffect(() => {
    const connect = async () => {
      const res = await fetch(`/api/negotiate`);
      const { url } = await res.json();
      const ws = new WebSocket(url);
      ws.onopen = () => console.log('connected');
      ws.onmessage = (event) => {
        console.log(JSON.stringify(event.data));
        //Code Logic
      };

    };
    connect();
  }, []);

  const checkNotificationSupport = () =>  {
    if (!('serviceWorker' in navigator)) {
      console.log("No support for service worker.")
      return false;
    }

    if (!('Notification' in window)) {
      console.log("No support for notification API");
      return false;
    }

    if (!('PushManager' in window)) {
      console.log("No support for Push API");
      return false;
    }
    console.log("Notifications API support detected");
    return true;
  }

  const checkNotificationPermission = () => {
    if (Notification.permission === "granted") {
      console.log("checkNotificationPermission: Notifications permission already granted");
      return true;
    } else {
      console.log("checkNotificationPermission: Notifications permission not already granted");
      return false;
    }
  }

  const requestNotificationPermission = async () => {
    if (Notification.permission === "granted") {
      console.log("Notifications permission already granted");
      return true;
    } else if (Notification.permission === "default") {
      const permission = await Notification.requestPermission();
      if (permission === "granted") {
        console.log("Notifications permission granted");
        return true;
      } else {
        console.log("Notification permission not granted");
        return false;
      }
    } else if (Notification.permission === "denied") {
      console.log("Notifications permission denied");
      return false;
    }
  }

  const registerSW = async () => {
    const registration = await navigator.serviceWorker.register('/service-worker.js');
    console.log("service worker registered");
    return registration;
  }

  const registerNotifications = async () => {
    console.log("registering notifications");
    if (checkNotificationSupport() && await requestNotificationPermission()) {
      await registerSW();
    }
    console.log("registered notifications!");
    showNotificationButton = checkNotificationSupport() && !checkNotificationPermission()
  }

  let showNotificationButton = checkNotificationSupport() && !checkNotificationPermission();

  return (
    <Router>
      <div className='main-container'>
      <Header />
      <div className="main-content">
        <Routes>
          <Route path="/" element={<DPEK />} />
          <Route path="/agenda" element={<Agenda sched={schedule?.schedule} />} />
          <Route path="/travel" element={<Travel />} />
          <Route path="/help" element={<Help />} />
        </Routes>
        {showNotificationButton && <button onClick={registerNotifications}>Enable Notifications</button>}
      </div>
      <nav>
        <ul className="tab-list">
          <li>
            <NavLink to="/" activeClassName="active-link">
              <img src={navDPEK} alt="DPEK" /><br />
              DPEK
            </NavLink>
          </li>
          <li>
            <NavLink to="/agenda" activeClassName="active-link">
              <img src={navAgenda} alt="Agenda" /><br />
              Agenda
            </NavLink>
          </li>
          <li>
            <NavLink to="/travel" activeClassName="active-link">
              <img src={navTravel} alt="Travel" /><br />
              Travel
            </NavLink>
          </li>
          <li>
            <NavLink to="/help" activeClassName="active-link">
              <img src={navHelp} alt="Help" /><br />
              Help
            </NavLink>
          </li>
        </ul>
      </nav>
      </div>
    </Router>
  );
}

export default App;
