import React from 'react';
import headerImg from '../images/header-help.jpg';
import appStore from '../images/concur-appstore.svg';
import googlePlay from '../images/concur-googleplay.svg';

function Help() {
  return (
    <div className="page-container">
      <div className="span-100" style={{ backgroundColor: "#370f6e" }}>
        <div className="max-500">
          <img src={headerImg} alt="" class="pageImage" style={{ height: 300, width: '100%', maxWidth: 500 }}/>
        </div>
      </div>
        
      <div className="span-100">
        <div className="max-500 page-content">
          <h2>Help</h2>
          
          <h3>Acendas Corporate Travel</h3>
          <p>Phone <a href="tel:1 913 586 7931">+1 913 586 7931</a></p>
          <p>Email <a href="email:reservations@acendas.com">reservations@acendas.com</a></p>

          <h3>Airlines</h3>
          <p>American <a href="tel:1 800 433 7300">+1 800 433 7300</a></p>
          <p>Delta <a href="tel:1 800 455 2720">+1 800 455 2720</a></p>
          <p>Jet Blue <a href="tel:1 800 538 2583">+1 800 538 2583</a></p>
          <p>Southwest <a href="tel:1 800 435 9792">+1 800 435 9792</a></p>
          <p>United <a href="tel:1 800 864 8331">+1 800 864 8331</a></p>

          <h3>Hilton Rosemont Chicago O'hare</h3>
          <p>Phone <a href="tel:1 844 209 0789">+1 844 209 0789</a></p>

          <h3>DFIN Tech Care Center</h3>
          <p>Phone <a href="tel:1 844 693 4648">+1 844 693 4648</a></p>
          <p>Email <a href="email:dfintechcarecenter@dfinsolutions.com">dfintechcarecenter@dfinsolutions.com</a></p>

          <h3>SAP Concur Expenses</h3>
          <p>
            <a href="https://apps.apple.com/us/app/sap-concur/id335023774"><img alt="" src={appStore} style={{ width: 150 }}/></a>&nbsp;
            <a href="https://play.google.com/store/apps/details?id=com.concur.breeze&hl=en-US&pli=1"><img alt="" src={googlePlay} style={{ width: 170 }}/></a>
          </p>
        </div>
      </div>
    </div>
    );
}

export default Help;
