import React from 'react';
import headerImg from '../images/header-travel.jpg';
import terminalImg from '../images/terminal.png';
import alertIcon from '../images/alert.svg';

function Travel() {
  return (
    <div className="page-container">
      <div className="span-100" style={{ backgroundColor: "#370f6e" }}>
        <div className="max-500">
          <img src={headerImg} alt="" class="pageImage" style={{ height: 300, width: '100%', maxWidth: 500 }}/>
        </div>
      </div>

      <div className="span-100">
        <div className="max-500 page-content">
          <div class="travel">
            <h2>Travel</h2>

            <h3>Arival Airport</h3>
            <p>OHare International Airport</p>
            <p>10000 West Ohare Ave</p>
            <p>Chicago, Il 60666</p>
            <p><a target='_blank' rel="noopener noreferrer" href="https://www.google.com/maps/search/?api=1&query=Chicago+O'Hare+International+Airport&query_place_id=ChIJ82J3aie0D4gRS61ZAgdHF1E">View Map</a></p>
            <br/>
            <p>Website <a href="http://flychicago.com" rel="noopener noreferrer" target='_blank'>flychicago.com</a></p>

            <h3>Ride Share</h3>
            <p>Ride share is located outside of terminal <strong>Terminal 2</strong>. Please attempt to travel together from the airport to the hotel. Most people should be arriving between 11am and 2pm.</p>
            <img src={terminalImg} alt="Airport terminal 2A"/>
            <p><img src={alertIcon} alt="Red triangle with exclamation point"/>&nbsp;Do not take the taxi! They are for trips to the city and charge additional fees.</p>

            <h3>Hilton Rosemont Chicago O'hare</h3>
            <p>5550 N River Rd</p>
            <p>Chicago, Il 60018</p>
            <p><a target='_blank' rel="noopener noreferrer" href="https://www.google.com/maps/search/?api=1&query=Hilton+Rosemont%2FChicago+O'Hare&query_place_id=ChIJkQT6kAe2D4gRvEs6UrwVqJ0">View Map</a></p>
            <br/>
            <p>Phone <a href="tel:1 844 209 0789">+1 844 209 0789</a></p>
            <p>Website <a href="https://www.hilton.com/en/hotels/ordhrhh-hilton-rosemont-chicago-ohare/" target='_blank' rel="noopener noreferrer" >hilton.com</a></p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Travel;
