import React from 'react';
import headerImg from '../images/header-dpek.jpg';
import sponsor1 from '../images/sponsor-cognizant.png';
import sponsor2 from '../images/sponsor-dragonspears.png';
import sponsor3 from '../images/sponsor-agility.png';
import sponsor4 from '../images/sponsor-endava.png';
import sponsor5 from '../images/sponsor-accenture.png';
import notification from '../images/notificationIcon.svg';

function DPEK() {
  return (
      <div className="page-container">
        <div className="span-100" style={{ backgroundColor: "#370f6e" }}>
          <div className="max-500">
            <img src={headerImg} alt="" class="pageImage" style={{ height: 300, width: '100%', maxWidth: 500 }}/>
          </div>
        </div>

        <div className="span-100" style={{ backgroundColor: "#8278aa", borderBottom: "3px #370f6e solid" }}>
          <div className="max-500" style={{ position: "relative" }}>
              <div style={{ height: 62, width: 90, textAlign: 'center', backgroundColor: '#370F6E', color: '#ffffff', paddingTop: 14 }}>
                <img src={notification} alt="Notification Bell Icon"/><br />
                UPDATE
              </div>
              <div style={{ height: 60, width: 'auto', maxWidth: 395, backgroundColor: '#F7F7F7', color: "#000000", padding: 8, position: "absolute", top: 0, left: 90 }}>
                Shuttle transportion to dinner will now be provided starting at 5pm instead of 6pm. If you want to take a ride share, you may.
              </div>
          </div>
        </div>

        <div class="span-100">
          <div className="set-width">
            <div className="max-500 page-content">
              <h2>DPEK</h2>

              <h3>Welcome</h3>
              <p>Welcome to DPEK 2025 back in Rosemont, Illinois. We continue to focus on our digital transformation as a key finanacial solutions provider to 
              some of the largest companies in the world. This year we will focus on the continuation of our transition to a SaaS software company through 
              improvements and enhancements in our current offerings which will bring more value to our customers and more value to our company through 
              customer retention. Enjoy the event! ~ Floyd Strimling</p>

              <h3>Sponsors</h3>
              <p>A special thank you to our esteemed sponsors. Please be sure to thank them when you see them.</p>
              <img src={sponsor1} alt="Accenture" style={{ marginRight: 10 }}/>
              <img src={sponsor2} alt="Agility" style={{ marginRight: 10 }}/>
              <img src={sponsor3} alt="DragonSpears" style={{ marginRight: 10 }}/>
              <img src={sponsor4} alt="Endava" style={{ marginRight: 10 }}/>
              <img src={sponsor5} alt="Perficient"/>

              <h3>Attire</h3>
              <p>What to wear? Sneakers, jeans, and hoodies. Keep it casual... but not too casual.</p>
            </div>
          </div>
        </div>
      </div>
    );
}

export default DPEK;
